import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "../../App.css";
import { AiOutlinePlusCircle, AiOutlineHome } from "react-icons/ai";
import { BsBank, BsQrCodeScan, BsArrowUpRightCircle } from "react-icons/bs";
import { BiTransfer } from "react-icons/bi";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import { setpayInStatus } from "../../redux/authentication/AuthenticationSlice";
import { useEffect } from "react";

const MoneyTransferBox = () => {
  const { loginData, isAuth } = useSelector((state) => state.Authentication);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [addMoneys, setAddMoney] = useState("");
  const [errormassage, setErrormassage] = useState("");
  const toggle = () => setModal(!modal);

  const [bankmodal, setBankModal] = useState(false);
  const bankToggle = () => setBankModal(!bankmodal);

  const [paymentLinkmodal, setPaymentLinkModal] = useState(false);
  const paymentLinkToggle = () => setPaymentLinkModal(!paymentLinkmodal);

  const [checkBalanceModal, setCheckBalanceModal] = useState(false);
  const checkBalanceToggle = () => setCheckBalanceModal(!checkBalanceModal);

  useEffect(() => {
    if (addMoneys !== "") {
      if (addMoneys >= 99) {
        setErrormassage("");
      } else {
        setErrormassage("Please enter amount morethen 100");
      }
    }
  }, [addMoneys]);

  const addMoney = async (e) => {
    // e.preventdefault();

    if (isAuth) {
      if (addMoneys >= 99) {
        setErrormassage("");
        const config = {
          Headers: { "Content-Type": "application/json" },
        };

        var date = new Date();
        const dts = date.toString();

        const formdata = {
          RequestDateTime: "19/12/2023",

          AcceptedPaymentTypes: "",
          PaymentType: "",
          SurchargeType: "",
          SurchargeValue: "",
          RefTransactionId: "",
          IndustrySpecificationCode: "",
          PartialPaymentOption: "",

          OrderType: "MOBILE",

          AmountTypeDesc: "des",
          Amount: String(addMoneys),

          CustomerId: loginData._id,
          CustomerNotes: "",
          FirstName: loginData.name,
          LastName: "",
          MobileNo: loginData.mobile,
          Email: loginData.email,
          EmailReceipt: "",
          BillingAddress: "",
          BillingCity: "",
          BillingState: "",
          BillingCountry: "",
          BillingZipCode: "",
          ShippingFirstName: "",
          ShippingLastName: "",
          ShippingAddress: "",
          ShippingCity: "",
          ShippingState: "",
          ShippingCountry: "",
          ShippingZipCode: "",
          ShippingMobileNo: "",
          UserName: loginData.name,
        };

        const currorder = await axios.post(
          `${Baseurl}/api/v1/order/create`,
          formdata,
          config
        );

        if (currorder.data.success === true) {
          const respdata = currorder.data.body;
          dispatch(setpayInStatus(respdata));
          window.location.replace(`${respdata.PaymentProcessUrl}`);
        }
      } else {
        setErrormassage("Please enter amount morethen 100");
      }
    } else {
      navigate("/login");
    }
  };

  const addVerifyMoney = (e) => {
    if (isAuth) {
      setModal(true);
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="moneyTransferMainContainer">
      <div className="col-12 d-flex justify-content-center moneyTransferSubContainer">
        <div className="col-10 containerBox">
          <div
            className="col-2 mx-1 boxes"
            style={{ cursor: "pointer" }}
            onClick={(e) => addVerifyMoney(e)}
          >
            <div className="iconContainer" onClick={(e) => addVerifyMoney(e)}>
              <AiOutlinePlusCircle
                style={{
                  color: "#7e58df",
                }}
                size={50}
              />
            </div>
            <div className="textContainer" onClick={(e) => addVerifyMoney(e)}>
              <h6 className="fonttext" onClick={(e) => addVerifyMoney(e)}>
                Add Money
              </h6>
            </div>
          </div>
          {/* Modal here starts */}
          <Modal
            isOpen={modal}
            toggle={() => setModal(!modal)}
            style={{ height: "50vh" }}
          >
            <ModalHeader toggle={toggle} className="modalHeaderStyles">
              Add Amount
            </ModalHeader>
            <ModalBody>
              <div>
                <div className="firstBox">
                  <h6 className="heading">Experiencce PIN-less payments!</h6>
                  <div className="d-flex mt-3">
                    <div className="d-flex align-items-center me-3">
                      <BsQrCodeScan className="iconQrCode" />
                    </div>
                    <div>
                      <p className="paytext">
                        Pay friends or scan at any store
                      </p>
                      <p className="paysubtext">100% aceeptable like UPI</p>
                    </div>
                  </div>
                  <div className="d-flex mt-3">
                    <div className="d-flex align-items-center me-3">
                      <BsBank className="iconQrCode" />
                    </div>
                    <div>
                      <p className="paytext">Add Money from bank Account</p>
                      <p className="paysubtext">Add draw to bank any time</p>
                    </div>
                  </div>
                  <div className="d-flex mt-3">
                    <div className="d-flex align-items-center me-3">
                      <BsArrowUpRightCircle className="iconQrCode" />
                    </div>
                    <div>
                      <p className="paytext">Zero failure</p>
                      <p className="paysubtext">
                        works even when your bank is down
                      </p>
                    </div>
                  </div>
                </div>
                <div className="secondBox">
                  <h6 className="heading">Add Money</h6>
                  <div className="mt-2" style={{ position: "relative" }}>
                    <span className="rupeesymbol">₹</span>
                    <input
                      type="text"
                      placeholder="Enter Amount"
                      className="inputbox"
                      value={addMoneys}
                      onChange={(e) => {
                        setErrormassage("");
                        const value = e.target.value.replace(/\D/g, "");
                        if (value >= 99 || value !== "") {
                          setAddMoney(value);
                          setErrormassage("");
                        } else {
                          setAddMoney("");
                          setErrormassage("Please enter amount morethen 100");
                        }
                      }}
                    />
                  </div>
                  <div className="mt-2">
                    <button
                      type="button"
                      className="amountbutton"
                      onClick={() => setAddMoney(200)}
                    >
                      ₹ 200
                    </button>
                    <button
                      type="button"
                      className="amountbutton"
                      onClick={() => setAddMoney(1000)}
                    >
                      ₹ 1000
                    </button>
                    <button
                      type="button"
                      className="amountbutton"
                      onClick={() => setAddMoney(2000)}
                    >
                      ₹ 2000
                    </button>
                  </div>
                  <div className="mt-2">
                    <p style={{ color: "red" }}>{errormassage}</p>
                  </div>

                  <div className="mt-2">
                    <button
                      type="button"
                      onClick={(e) => addMoney(e)}
                      className="addamountbutton"
                    >
                      ADD MONEY
                    </button>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
          {/* Modal here ends  */}
          <div
            className="col-2 mx-1 boxes"
            style={{ cursor: "pointer" }}
            onClick={bankToggle}
          >
            <div className="iconContainer">
              <AiOutlineHome
                style={{
                  color: "#7e58df",
                }}
                size={50}
              />
            </div>
            <div className="textContainer">
              <h6 className="fonttext">To Bank</h6>
            </div>
            {/* Modal here starts */}
            <Modal style={{ height: "50vh" }}>
              <ModalHeader className="modalHeaderStyles">To Bank</ModalHeader>
              <ModalBody>
                <div>
                  <div className="firstBox">
                    <h6 className="heading">Experiencce PIN-less payments!</h6>
                    <div className="d-flex mt-3">
                      <div className="d-flex align-items-center me-3">
                        <BsQrCodeScan className="iconQrCode" />
                      </div>
                      <div>
                        <p className="paytext">
                          Pay friends or scan at any store
                        </p>
                        <p className="paysubtext">100% aceeptable like UPI</p>
                      </div>
                    </div>
                    <div className="d-flex mt-3">
                      <div className="d-flex align-items-center me-3">
                        <BsBank className="iconQrCode" />
                      </div>
                      <div>
                        <p className="paytext">Add Money from bank Account</p>
                        <p className="paysubtext">Add draw to bank any time</p>
                      </div>
                    </div>
                    <div className="d-flex mt-3">
                      <div className="d-flex align-items-center me-3">
                        <BsArrowUpRightCircle className="iconQrCode" />
                      </div>
                      <div>
                        <p className="paytext">Zero failure</p>
                        <p className="paysubtext">
                          works even when your bank is down
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="secondBox">
                    <h6 className="heading">Add Money</h6>
                    <div className="mt-2" style={{ position: "relative" }}>
                      <span className="rupeesymbol">₹</span>
                      <input
                        type="text"
                        placeholder="Enter Amount"
                        className="inputbox"
                      />
                    </div>
                    <div className="mt-2">
                      <button type="button" className="amountbutton">
                        ₹ 200
                      </button>
                      <button type="button" className="amountbutton">
                        ₹ 1000
                      </button>
                      <button type="button" className="amountbutton">
                        ₹ 2000
                      </button>
                    </div>
                    <div className="mt-2">
                      <button type="button" className="addamountbutton">
                        ADD MONEY
                      </button>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
            {/* Modal here ends  */}
          </div>
          <div
            className="col-2 mx-1 boxes"
            style={{ cursor: "pointer" }}
            onClick={checkBalanceToggle}
          >
            <div className="iconContainer">
              <BsBank
                style={{
                  color: "#7e58df",
                }}
                size={43}
              />
            </div>
            <div className="textContainer">
              <h6 className="fonttext">Check Balance</h6>
            </div>
            {/* Modal here starts */}
            <Modal
              isOpen={checkBalanceModal}
              toggle={checkBalanceToggle}
              // style={{ height: "10vh" }}
            >
              <ModalHeader
                toggle={checkBalanceToggle}
                className="modalHeaderStyles"
              >
                Check Balance
              </ModalHeader>
              <ModalBody>
                <div className="firstBox">
                  <h6 className="heading">
                    Available Balance : {loginData.mainWallet}
                  </h6>
                </div>
                <div className="firstBox mt-2">
              
                  <h6 className="heading">Commission Earn : 0</h6>
                </div>
              </ModalBody>
            </Modal>
            {/* Modal here ends  */}
          </div>
          {/* <div
            className="col-2 mx-1 boxes"
            style={{ cursor: "pointer" }}
            onClick={paymentLinkToggle}
          >
            <div className="iconContainer">
              <BiTransfer
                style={{
                  color: "#7e58df",
                }}
                size={50}
              />
            </div>
            <div className="textContainer">
              <h6 className="fonttext">Payment Link</h6>
            </div>
       
          </div> */}
          {/* Modal here starts */}
          <Modal
            isOpen={bankmodal}
            toggle={bankToggle}
            style={{ height: "50vh" }}
          >
            <ModalHeader toggle={bankToggle} className="modalHeaderStyles">
              BANK DETAILS
            </ModalHeader>
            <ModalBody className="modal-body">
              <div>
                <div>
                  <div className="input-title">Bank Benificiary Name:</div>
                  <input
                    type="text"
                    placeholder="Enter Bank Account Benificiary Name"
                    className="inputbox"
                  />
                </div>
                <div>
                  <div className="input-title">Bank Account Number:</div>
                  <input
                    type="text"
                    placeholder="Bank Account Number"
                    className="inputbox"
                  />
                </div>
                <div>
                  <div className="input-title">Bank Benificiary Name:</div>
                  <input
                    type="text"
                    placeholder="Enter IFSC Code"
                    className="inputbox"
                  />
                </div>
                <div>
                  <div className="input-title">Bank Branch Name:</div>
                  <input
                    type="text"
                    placeholder="Enter Bank Branch Name"
                    className="inputbox"
                  />
                </div>

                <div className="mt-2">
                  <button type="button" className="addamountbutton">
                    ADD BANK
                  </button>
                </div>
              </div>
            </ModalBody>
          </Modal>
          {/* Modal here ends  */}
        </div>
      </div>
    </div>
  );
};

export default MoneyTransferBox;
