import React from "react";
import { Link } from "react-router-dom";

const CancellationRefund = () => {
  return (
    <>
      <section
        className="breadcrumb__area pt-165 pb-150 p-relative z-index-1 fix"
        data-bg-color="#16243E"
        style={{ background: "#7e58df" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <div className="breadcrumb__content">
                <h3 className="breadcrumb__title" style={{ fontSize: "35px" }}>
                  Cancellation & Refund
                </h3>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="breadcrumb__content">
                <div className="breadcrumb__list text-center text-sm-end">
                  <span>
                    <Link to="/">Home</Link>
                  </span>
                  <span className="dvdr">
                    <i className="fa-regular fa-angle-right" />
                  </span>
                  <span>Cancellation & Refund</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container mt-lg-4">
        <section className="tp-faq-breadcrumb-area pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="tp-faq-breadcrumb-tab-content tp-accordion">
                  <div className="accordion" id="general_accordion">
                    <div className="accordion-item tp-faq-active">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button"
                          type=""
                          data-bs-toggle=""
                          data-bs-target="#"
                          aria-expanded="true"
                          aria-controls=""
                        >
                          Cancellation and Refund Policy – SUPER REX (ABIPAY)
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#general_accordion"
                      >
                        <div className="accordion-body">
                          <h4>
                            By accessing SUPER REX (ABIPAY) and using its
                            services, you agree to the terms and conditions
                            without limitation or qualification.
                          </h4>
                          <h4>
                            Refund amount will be share with in 7 working days of time with same source account.
                          </h4>
                          <p>
                            SUPER REX (ABIPAY)  reserves the right to amend the
                            terms at any time and without prior notice to
                            clients. You agree to be bound and regulated by the
                            terms specified if you continue to use SUPER REX
                            (ABIPAY)  after any updates. If and when they are
                            made, privacy policies, disclaimers, and other
                            applicable policies in general or specialised areas
                            of the website, SUPER REX (ABIPAY) .or to a specific
                            service are also considered terms.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className="tp-faq-breadcrumb-area pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-faq-breadcrumb-tab-content tp-accordion">
                <div className="accordion" id="general_accordion">
                  <div className="accordion-item tp-faq-active">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type=""
                        data-bs-toggle=""
                        data-bs-target="#"
                        aria-expanded="true"
                        aria-controls=""
                      >
                        Refund and Cancellation Charges Policy
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#general_accordion"
                    >
                      <h4>
                        Refund amount will be share with in 7 working days of time with same source account.
                      </h4>
                      <p>
                        During any transaction between the user and the merchant
                        or service provider, SUPER REX (ABIPAY)  does not take control of
                        any of the services or products provided by the
                        seller/merchant. SUPER REX (ABIPAY)also offers no
                        guarantees or claims concerning the items or services
                        that are provided to the consumer. If a refund or
                        cancellation is required, the aggregator's or
                        vendor's/terms merchant's and conditions will govern.
                        Cancellation and refund fees are beyond the authority of
                        SUPER REX (ABIPAY) . SUPER REX (ABIPAY)  is not
                        responsible for any refunds or cancellations, including
                        any expenses incurred as a result of such cancellations
                        or refunds.
                      </p>
                      <p>
                        Make an incorrect payment to the wrong person or send
                        payment with the incorrect amount. SUPER REX
                        (ABIPAY)will not be liable, and your only option will be
                        to contact the third party to whom the payment was
                        received and request a refund if applicable. SUPER REX
                        (ABIPAY)will not issue refunds or reverse payments made
                        by users in error.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tp-faq-breadcrumb-area pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-faq-breadcrumb-tab-content tp-accordion">
                <div className="accordion" id="general_accordion">
                  <div className="accordion-item tp-faq-active">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type=""
                        data-bs-toggle=""
                        data-bs-target="#"
                        aria-expanded="true"
                        aria-controls=""
                      >
                        Prepaid Recharge Policy
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#general_accordion"
                    >
                      <div className="accordion-body">
                        <h4>
                          Refund amount will be share with in 7 working days of time with same source account.
                        </h4>
                        <p>
                          All prepaid recharge sales made through the SUPER REX
                          (ABIPAY)site are final, with no refunds or exchanges
                          available. The user is liable for the actions of the
                          cell phone number or DTH account number for whom the
                          user has made a prepaid service purchase and all
                          charges incurred as a result of such purchase. The
                          user is solely responsible for all information
                          provided about the data card and related recharge
                          services, as well as all charges incurred as a result
                          of those purchases. SUPER REX (ABIPAY)is not
                          responsible for any prepaid recharge transactions made
                          with an inaccurate mobile number, DTH number, or data
                          card information and or connected services.
                        </p>
                        <h4>
                          <b>User Agreement and Responsibility</b>
                        </h4>
                      </div>
                      <p>
                        SUPER REX (ABIPAY)disclaims any legal, financial, or
                        other liability that may occur as a result of, or be
                        caused by, any act, omission, or otherwise of any user
                        on its website/mobile application. The user is
                        responsible for expressly agreeing that SUPER REX
                        (ABIPAY)is simply a payment facilitator and
                        intermediary. As a result, the user is released from any
                        liability that may come from this. Furthermore, the user
                        acknowledges that you implicitly agree to this
                        disclaimer by accessing or using SUPER REX
                        (ABIPAY) /Mobile App.
                      </p>
                      <div className="accordion-body">
                        <p>
                          The user is encouraged to read the merchant's or
                          vendor's services and terms and conditions carefully.
                          SUPER REX (ABIPAY)is not responsible if you are unable
                          to access the terms and conditions or suffer a loss
                          due to your failure to do so. The user is responsible
                          for acknowledging and agreeing that the actual sale
                          contract is made directly between the user and the
                          merchant/vendor. SUPER REX (ABIPAY)has no control over
                          or prevents any changes to the details published and
                          descriptions of the aggregator's or merchant's
                          website/applications and is not responsible for any
                          content contained within.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tp-faq-breadcrumb-area pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-faq-breadcrumb-tab-content tp-accordion">
                <div className="accordion" id="general_accordion">
                  <div className="accordion-item tp-faq-active">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type=""
                        data-bs-toggle=""
                        data-bs-target="#"
                        aria-expanded="true"
                        aria-controls=""
                      >
                        Accountability of the Company
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#general_accordion"
                    >
                      <p>
                        SUPER REX (ABIPAY)has no control over the things
                        featured, their existence, safety, quality, or legality,
                        the accuracy of the aggregator's content or listings, or
                        the merchant/capacity vendors to sell the items or offer
                        the services. SUPER REX (ABIPAY)does not control any of
                        the listings, bookings, or services given by the
                        merchant/vendor at any stage throughout the transaction
                        between the user and the merchant/vendor.
                      </p>
                      <div className="accordion-body">
                        <p>
                          In the event of a dispute, SUPER REX (ABIPAY)records
                          should constitute indisputable evidence of the
                          transactions carried out through the use of the user's
                          account and financial holdings.
                        </p>
                        <p>
                          The user is entirely responsible for any information
                          provided by him or her, including his or her bank card
                          data and any other papers he or she provides. The
                          company cannot be held liable for any disputes or
                          erroneous payments made by the user. In the event of
                          applicable faults or any other genuine disagreement,
                          the company will consider cancellation or
                          reimbursement.
                        </p>
                        <p>
                          If the company has to commence any refunds in the
                          user's account. In that case, the company will notify
                          him or her. The company will initiate the refund
                          procedure in a few business days after ultimately
                          confirming the refund method and verifying the
                          legitimacy of the reason supplied by the user.
                        </p>
                        <p>
                          All of the procedures provided here are in full
                          compliance with SUPER REX's most recent laws and
                          regulations. They will not accept any deceptive
                          practices used by consumers to gain refunds. Users are
                          expected to adhere to the company's general terms and
                          conditions and are bound by the company's criteria.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CancellationRefund;
