import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { authActions } from "../../redux/authentication/AuthenticationSlice";
import { removetransactionbyClientId } from "../../redux/transactions/TransactionSlice";

const Hadear = () => {
  const { loginData, isAuth } = useSelector((state) => state.Authentication);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);

  const liLogOut = () => {
    dispatch(authActions.signout());
    dispatch(removetransactionbyClientId());
    navigate("/");
  };
  return (
    <>
      <header className="tp-header-area p-relative">
        <div className="tp-header-box p-relative">
          <div className="tp-header-logo p-relative">
            <span className="tp-header-logo-bg" />
            <Link to="/">
              <img src="assets/img/logo/logo2.png" alt />
            </Link>
          </div>
          <div className="tp-header-wrapper-inner header__sticky p-relative">
            <div className="tp-header-top d-none d-xl-flex">
              <div className="tp-header-top-info">
                <Link
                  to=""
                  style={{
                    visibility: "hidden",
                  }}
                >
                  <span>
                    <i className="fa-sharp fa-solid fa-location-dot" />
                  </span>
                  Sirivella Mandal,VenkataPuram, Kurnool, AndhraPradesh
                </Link>
              </div>
              <div className="tp-header-top-right d-flex justify-content-end align-items-center">
                {/* <div className="header-social ">
                  <Link to="#">
                    <i className="fa-brands fa-facebook-f" />
                  </Link>
                  <Link to="#">
                    <i className="fa-brands fa-instagram" />
                  </Link>
                  <Link to="#">
                    <i className="fa-brands fa-twitter" />
                  </Link>
                  <Link to="#">
                    <i className="fa-brands fa-linkedin" />
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="tp-header-main-menu d-flex align-items-center justify-content-between">
              <div className="tp-main-menu d-none d-xl-block">
                <nav className="tp-main-menu-content">
                  <ul>
                    <li className="">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="">
                      <Link to="/services">Services</Link>
                    </li>

                    {isAuth ? (
                      <>
                        <li>
                          <Link to="/profile">Profile</Link>
                        </li>
                        <li>
                          <Link to="/listsItems">History</Link>
                        </li>
                        <li onClick={() => liLogOut()}>
                          <Link to="/login">Logout</Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <Link to="/about">About Us</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contact</Link>
                        </li>
                        <li>
                          <Link to="/login">Login</Link>
                        </li>
                      </>
                    )}
                  </ul>
                </nav>
              </div>
              <div className="tp-header-main-right d-flex align-items-center justify-content-xl-end">
                <div className="tp-header-contact d-xl-flex align-items-center d-none">
                  {/* <div className="tp-header-contact-search search-open-btn d-none d-xxl-block">
                    <span>
                      <i className="fa-solid fa-magnifying-glass" />
                    </span>
                  </div> */}
                  <div
                    className="tp-header-contact-inner d-flex align-items-center"
                    style={{
                      visibility: "hidden",
                    }}
                  >
                    <div className="tp-header-contact-icon">
                      <span>
                        <i className="fa-solid fa-phone" />
                      </span>
                    </div>
                    <div className="tp-header-contact-content">
                      <p>Contact Us:</p>
                      <span>
                        <a href="tel:917013914767">(+91) 7013914767</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tp-header-btn">
            <Link className="tp-btn d-none d-xl-block" to="/contact">
              Contact Us <i className="fa-regular fa-arrow-right" />
            </Link>
            {toggle === false ? (
              <>
                <div
                  className="tp-header-main-right-hamburger-btn d-xl-none offcanvas-open-btn"
                  onClick={() => setToggle(!toggle)}
                >
                  <button
                    className="hamburger-btn"
                    onClick={() => setToggle(!toggle)}
                  >
                    <span />
                    <span />
                    <span />
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="offcanvas__close">
                  <button className="offcanvas__close-btn offcanvas-close-btn">
                    <svg
                      width={12}
                      height={12}
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 1L1 11"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1 1L11 11"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div>x</div>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </header>
      {toggle === true ? (
        <>
          <div
            className="offcanvas__area offcanvas-opened"
            onClick={() => setToggle(!toggle)}
          >
            <div
              className="offcanvas__wrapper"
              onClick={() => setToggle(!toggle)}
            >
              <div className="offcanvas__content">
                <div className="tp-main-menu-mobile fix d-xl-none mb-40">
                  <nav className="tp-main-menu-content">
                    <ul>
                      <li className="" onClick={() => setToggle(!toggle)}>
                        <a to="">
                          <span>Home</span>
                        </a>
                      </li>
                      {isAuth ? (
                        <>
                          <li className="" onClick={() => setToggle(!toggle)}>
                            <Link to="/services">
                              <span>Services</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/profile">Profile</Link>
                          </li>
                          <li>
                            <Link to="/listsItems">History</Link>
                          </li>
                          <li onClick={() => liLogOut()}>
                            <Link to="/">Logout</Link>
                          </li>
                        </>
                      ) : (
                        <>
                          <li>
                            <Link
                              to="/about"
                              onClick={() => setToggle(!toggle)}
                            >
                              <span>About Us</span>
                            </Link>
                          </li>
                          <li className="" onClick={() => setToggle(!toggle)}>
                            <Link to="/services">
                              <span>Services</span>
                            </Link>
                          </li>
                          <li onClick={() => setToggle(!toggle)}>
                            <Link to="/contact">
                              <span>Contact</span>
                            </Link>
                          </li>
                          <li onClick={() => setToggle(!toggle)}>
                            <Link to="/login">
                              <span>Login</span>
                            </Link>
                          </li>
                        </>
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Hadear;
