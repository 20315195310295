import { HashRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import { useEffect } from "react";
import MainLayOut from "./components/MainLayOut";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CancellationRefund from "./pages/CancellationRefund";
import TermsConditions from "./pages/TermsConditions";
import ServicesBBPS from "./pages/home/ServicesBBPS";
import Service from "./pages/home/Service";
import MoneyTransferHistory from "./pages/MoneyTransferHistory";
import Login from "./pages/Login";
import ListsItems from "./pages/ListsItems";
import AddMoneySuccess from "./pages/AddMoneySuccess";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Baseurl2 } from "./config/BaseUrl";
import { transactionbyClientId } from "./redux/transactions/TransactionSlice";
import ProfileSection from "./pages/ProfileSection";
import Registation from "./pages/Registation";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}




function App() {
  const { loginData, isAuth } = useSelector((state) => state.Authentication);

  const dispatch = useDispatch();

  useEffect(() => {

    if (isAuth) {
      const getTransaction = async (e) => {

        const getTrans = await axios.get(
          `${Baseurl2}/api/v1/banktransaction/user/${loginData._id}`
        );

        if (getTrans.data.success === true) {
          const respdata = getTrans.data.banktransactions;
          dispatch(transactionbyClientId(respdata));
        }
      }
      getTransaction()
    }


  }, [isAuth]);
  return (
    <>
      <HashRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/profile" element={<ProfileSection />} />
            <Route path="/servicesBBPS" element={<ServicesBBPS />} />
            <Route path="/services" element={<Services />} />
            <Route path="/service" element={<Service />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/listsItems" element={<ListsItems />} />
            <Route path="/termsconditions" element={<TermsConditions />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/success" element={<AddMoneySuccess />} />

            <Route
              path="/cancellationrefund"
              element={<CancellationRefund />}
            />

            <Route
              path="/money-transfer-history"
              element={<MoneyTransferHistory />}
            />

            <Route
              path="/login"
              element={<Login />}
            />
            <Route
              path="/registation"
              element={<Registation />}
            />

          </Route>
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
