import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <>
      <section
        className="breadcrumb__area pt-165 pb-150 p-relative z-index-1 fix"
        data-bg-color="#16243E"
        style={{ background: "#7e58df" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <div className="breadcrumb__content">
                <h3 className="breadcrumb__title" style={{ fontSize: "35px" }}>
                  Privacy Policy
                </h3>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="breadcrumb__content">
                <div className="breadcrumb__list text-center text-sm-end">
                  <span>
                    <Link to="/">Home</Link>
                  </span>
                  <span className="dvdr">
                    <i className="fa-regular fa-angle-right" />
                  </span>
                  <span>Privacy Policy</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container mt-lg-4">
        <section className="tp-faq-breadcrumb-area pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="tp-faq-breadcrumb-tab-content tp-accordion">
                  <div className="accordion" id="general_accordion">
                    <div className="accordion-item tp-faq-active">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button"
                          type=""
                          data-bs-toggle=""
                          data-bs-target="#"
                          aria-expanded="true"
                          aria-controls=""
                        >
                          Privacy Policy for Abipay
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#general_accordion"
                      >
                        <div className="accordion-body">
                          <p>
                            At Abipay, accessible from https://www.Abipay.in,
                            one of our main priorities is the privacy of our
                            visitors. This Privacy Policy document contains
                            types of information that is collected and recorded
                            by Abipay and how we use it.
                          </p>
                          <p>
                            If you have additional questions or require more
                            information about our Privacy Policy, do not
                            hesitate to contact us.
                          </p>
                          <p>
                            This Privacy Policy applies only to our online
                            activities and is valid for visitors to our website
                            with regards to the information that they shared
                            and/or collect in Abipay. This policy is not
                            applicable to any information collected offline or
                            via channels other than this website. Our Privacy
                            Policy was created with the help of the Free Privacy
                            Policy Generator.
                          </p>
                        </div>
                        <h4>Consent</h4>

                        <div className="accordion-body">
                          <p>
                            By using our website, you hereby consent to our
                            Privacy Policy and agree to its terms.
                          </p>
                          <h4>Information we collect</h4>
                          <p>
                            The personal information that you are asked to
                            provide, and the reasons why you are asked to
                            provide it, will be made clear to you at the point
                            we ask you to provide your personal information.
                          </p>
                          <p>
                            If you contact us directly, we may receive
                            additional information about you such as your name,
                            email address, phone number, the contents of the
                            message and/or attachments you may send us, and any
                            other information you may choose to provide.
                          </p>
                          <p>
                            When you register for an Account, we may ask for
                            your contact information, including items such as
                            name, company name, address, email address, and
                            telephone number.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className="tp-faq-breadcrumb-area pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-faq-breadcrumb-tab-content tp-accordion">
                <div className="accordion" id="general_accordion">
                  <div className="accordion-item tp-faq-active">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type=""
                        data-bs-toggle=""
                        data-bs-target="#"
                        aria-expanded="true"
                        aria-controls=""
                      >
                        How we use your information
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#general_accordion"
                    >
                      <h4>
                        We use the information we collect in various ways,
                        including to:
                      </h4>
                      <div className="accordion-body">
                        <ul>
                          <li>Provide, operate, and maintain our website</li>
                          <li>Improve, personalize, and expand our website</li>
                          <li>
                            Understand and analyze how you use our website
                          </li>
                          <li>
                            Develop new products, services, features, and
                            functionality
                          </li>
                          <li>
                            Communicate with you, either directly or through
                            one of our partners, including for customer service,
                            to provide you with updates and other information
                            relating to the website, and for marketing and
                            promotional purposes
                          </li>
                          <li>Send you emails</li>
                          <li>Find and prevent fraud</li>
                        </ul>
                      </div>
                      <h4>Log Files</h4>
                      <div className="accordion-body">
                        <p>
                          Abipay follows a standard procedure of using log
                          files. These files log visitors when they visit
                          websites. All hosting companies do this and a part of
                          hosting services' analytics. The information collected
                          by log files include internet protocol (IP) addresses,
                          browser type, Internet Service Provider (ISP), date
                          and time stamp, referring/exit pages, and possibly the
                          number of clicks. These are not linked to any
                          information that is personally identifiable. The
                          purpose of the information is for analyzing trends,
                          administering the site, tracking users' movement on
                          the website, and gathering demographic information.
                        </p>
                      </div>
                      <h4>Cookies and Web Beacons</h4>
                      <div className="accordion-body">
                        <p>
                          Like any other website, Abipay uses 'cookies'. These
                          cookies are used to store information including
                          visitors' preferences, and the pages on the website
                          that the visitor accessed or visited. The information
                          is used to optimize the users' experience by
                          customizing our web page content based on visitors'
                          browser type and/or other information.
                        </p>
                        <p>
                          <b>
                            For more general information on cookies, please
                            read more on the Cookie Consent website
                          </b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tp-faq-breadcrumb-area pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-faq-breadcrumb-tab-content tp-accordion">
                <div className="accordion" id="general_accordion">
                  <div className="accordion-item tp-faq-active">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type=""
                        data-bs-toggle=""
                        data-bs-target="#"
                        aria-expanded="true"
                        aria-controls=""
                      >
                        Advertising Partners Privacy Policies
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#general_accordion"
                    >
                      <h4>
                        You may consult this list to find the Privacy Policy for
                        each of the advertising partners of Abipay
                      </h4>
                      <div className="accordion-body">
                        <p>
                          Third-party ad servers or ad networks uses
                          technologies like cookies, JavaScript, or Web Beacons
                          that are used in their respective advertisements and
                          links that appear on abipay, which are sent directly
                          to users' browser. They automatically receive your IP
                          address when this occurs. These technologies are used
                          to measure the effectiveness of their advertising
                          campaigns and/or to personalize the advertising
                          content that you see on websites that you visit.
                        </p>
                        <p>
                          <b>
                            Note that Abipay has no access to or control over
                            these cookies that are used by third-party
                            advertisers.
                          </b>
                        </p>
                      </div>
                      <h4>Third Party Privacy Policies</h4>
                      <p>
                        Abipay's Privacy Policy does not apply to other
                        advertisers or websites. Thus, we are advising you to
                        consult the respective Privacy Policies of these
                        third-party ad servers for more detailed information. It
                        may include their practices and instructions about how
                        to opt-out of certain options.
                      </p>
                      <div className="accordion-body">
                        <p>
                          You can choose to disable cookies through your
                          individual browser options. To know more detailed
                          information about cookie management with specific web
                          browsers, it can be found at the browsers' respective
                          websites.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tp-faq-breadcrumb-area pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-faq-breadcrumb-tab-content tp-accordion">
                <div className="accordion" id="general_accordion">
                  <div className="accordion-item tp-faq-active">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type=""
                        data-bs-toggle=""
                        data-bs-target="#"
                        aria-expanded="true"
                        aria-controls=""
                      >
                        CCPA Privacy Rights (Do Not Sell My Personal
                        Information)
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#general_accordion"
                    >
                      <h4>
                        Under the CCPA, among other rights, California consumers
                        have the right to:
                      </h4>
                      <div className="accordion-body">
                        <p>
                          Request that a business that collects a consumer's
                          personal data disclose the categories and specific
                          pieces of personal data that a business has collected
                          about consumers.
                        </p>
                        <p>
                          Request that a business delete any personal data about
                          the consumer that a business has collected.
                        </p>
                        <p>
                          Request that a business that sells a consumer's
                          personal data, not sell the consumer's personal data.
                        </p>
                        <p>
                          If you make a request, we have one month to respond to
                          you. If you would like to exercise any of these
                          rights, please contact us.
                        </p>
                      </div>
                      <h4>GDPR Data Protection Rights</h4>

                      <div className="accordion-body">
                        <p>
                          We would like to make sure you are fully aware of all
                          of your data protection rights. Every user is entitled
                          to the following:
                        </p>
                        <p>
                          <b>The right to access –</b> You have the right to
                          request copies of your personal data. We may charge
                          you a small fee for this service.
                        </p>
                        <p>
                          <b> The right to rectification – </b> You have the
                          right to request that we correct any information you
                          believe is inaccurate. You also have the right to
                          request that we complete the information you believe
                          is incomplete.
                        </p>
                        <p>
                          <b>The right to erasure – </b> You have the right to
                          request that we erase your personal data, under
                          certain conditions.
                        </p>
                        <p>
                          <b>
                            <b> The right to restrict processing – </b>You have
                            the right to request that we restrict the processing
                            of your personal data, under certain conditions.
                          </b>
                          <p>
                            <b>The right to object to processing – </b> You have
                            the right to object to our processing of your
                            personal data, under certain conditions.
                          </p>
                          <p>
                            SUPER REX (ABIPAY) Shall Not Be A Party To The
                            Agreement Between The User And Any Payee In Any
                            Manner Whatsoever. All Contracts Are Directly
                            Between Users And Their Payees. User Shall Be
                            Responsible For Any Miscommunication Or Incorrect
                            User/ Third Party/ Other Information That May Be
                            Provided To SUPER REX (ABIPAY) At The Time Of
                            Enabling The Services For The User.
                          </p>
                          <p>
                            The right to data portability – You have the right
                            to request that we transfer the data that we have
                            collected to another organization, or directly to
                            you, under certain conditions.
                          </p>
                          <h4>
                            If you make a request, we have one month to respond
                            to you. If you would like to exercise any of these
                            rights, please contact us.
                          </h4>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tp-faq-breadcrumb-area pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-faq-breadcrumb-tab-content tp-accordion">
                <div className="accordion" id="general_accordion">
                  <div className="accordion-item tp-faq-active">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type=""
                        data-bs-toggle=""
                        data-bs-target="#"
                        aria-expanded="true"
                        aria-controls=""
                      >
                        Children's Information
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#general_accordion"
                    >
                      <p>
                        Another part of our priority is adding protection for
                        children while using the internet. We encourage parents
                        and guardians to observe, participate in, and/or monitor
                        and guide their online activity.
                      </p>
                      <div className="accordion-body">
                        <p>
                          Abipay does not knowingly collect any Personal
                          Identifiable Information from children under the age
                          of 13. If you think that your child provided this kind
                          of information on our website, we strongly encourage
                          you to contact us immediately and we will do our best
                          efforts to promptly remove such information from our
                          records.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
