import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import "../App.css";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";

const ListsItems = () => {
  const { transactionByUserId } = useSelector((state) => state.Transaction);

  return (
    <>
      <section className="history-container">
        <div className="historyMainBox">
          <div className="historyname">
            <h5>History</h5>
          </div>
          <MDBTable>
            <MDBTableHead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Payment Type</th>
                <th scope="col">Amount</th>
                <th scope="col">Status</th>
                <th scope="col">Transaction Mode</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {transactionByUserId.map((data, index) => (
                <tr>
                  <th scope="row">{data.name}</th>
                  <td>{data.statustext}</td>
                  <td>{data.totalamt}</td>
                  <td>{data.remark}</td>
                  <td>{data.cardType}</td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
        </div>
      </section>
      {/* <MDBTable>
        <MDBTableHead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">First</th>
            <th scope="col">Last</th>
            <th scope="col">Handle</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <th scope="row">1</th>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td>Jacob</td>
            <td>Thornton</td>
            <td>@fat</td>
          </tr>
          <tr>
            <th scope="row">3</th>
            <td>Larry the Bird</td>
            <td>Larry the Bird</td>
            <td>@twitter</td>
          </tr>
        </MDBTableBody>
      </MDBTable> */}
    </>
  );
};

export default ListsItems;
