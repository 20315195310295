import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Baseurl, Baseurl2 } from "../config/BaseUrl";
import { newtransaction } from "../redux/transactions/TransactionSlice";
import { authActions } from "../redux/authentication/AuthenticationSlice";

const AddMoneySuccess = () => {
  const { loginData, isAuth, payInStatus } = useSelector(
    (state) => state.Authentication
  );
  const [tempLoading, settempLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth && payInStatus !== "") {
      const getOrderDetails = async () => {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };

        const formdata = {
          OrderKeyId: payInStatus.OrderKeyId,
        };

        const orderdetails = await axios.post(
          `${Baseurl}/api/v1/order/orderdetails`,
          formdata,
          config
        );
        if (orderdetails.data.success === true) {
          const respo = orderdetails.data.body;
          if (respo.PaymentResponseText === "Approved") {
            const userForm = {
              userId: loginData._id,
              mainWallet: payInStatus.OrderAmount + loginData.mainWallet,
            };

            const userUpdate = await axios.put(
              `${Baseurl2}/api/v1/user/userupdate/${userForm.userId}`,
              userForm,
              config
            );
            if (userUpdate.data.success === true) {
              const loginDetails = {
                ...userUpdate.data.user,
                isAuth: true,
              };
              dispatch(authActions.signin(loginDetails));
              // navigate("/");
            }
          }

          const postformdata = {
            userId: loginData._id,
            name: loginData.name,
            mobile: loginData.mobile,
            remark: respo.OrderPaymentStatusText,
            // remark: respo.PaymentResponseText,
            cardType: respo.PaymentMethod,
            transactionId: payInStatus.OrderKeyId,
            totalamt: payInStatus.OrderAmount,
            transactioncost: payInStatus.OrderAmount,
            amountafterDeduction: payInStatus.OrderAmount,
            gstontransaction: 0,
          };
          const postTran = await axios.post(
            `${Baseurl2}/api/v1/banktransaction/new`,
            postformdata,
            config
          );

          if (postTran.data.success === true) {
            const respo = postTran.data.banktransaction;
            dispatch(newtransaction(respo));
          }
        }
      };

      if (!tempLoading) {
        settempLoading(true);
        getOrderDetails();
      }
    } else {
      navigate("/");
    }
  }, [loginData, isAuth, payInStatus]);

  return <div>AddMoneySuccess</div>;
};

export default AddMoneySuccess;
