import Form from "react-bootstrap/Form";
import React, { useState, useLayoutEffect, useEffect } from "react";
import { Baseurl2 } from "../config/BaseUrl";
import axios from "axios";
import {
  authActions,
  userShopImage,
} from "../redux/authentication/AuthenticationSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const Registation = () => {
  const { shopImages, isShopImageLoading } = useSelector(
    (state) => state.Authentication
  );
  const [toggleCheckBox, setToggleCheckBox] = useState(false);
  const [showPassword, setShowPassword] = useState(true);

  const [selectedImages, setSelectedImages] = useState("");
  const [selectImageUrl, setSelectImageUrl] = useState("");
  const [value, setValue] = useState("Retailer");
  const [isFocus, setIsFocus] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailcnf, setEmailcnf] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileCnf, setMobileCnf] = useState("");
  const [sponcermobile, setSponcermobile] = useState("");
  const [altmobile, setaltmobile] = useState("");
  const [adharCard, setadharCard] = useState("");
  const [panCard, setpanCard] = useState("");
  const [avatar, setavatar] = useState("");
  const [role, setrole] = useState("");
  const [contact_id, setcontact_id] = useState("");
  const [kycStatus, setkycStatus] = useState("");
  const [referMob, setreferMob] = useState("");
  const [place, setplace] = useState("");
  const [distict, setdistict] = useState("");
  const [shopAddress, setshopAddress] = useState("");
  const [pincode, setpincode] = useState("");
  const [percentage, setpercentage] = useState("1.2");
  const [shopImage, setshopImage] = useState("");
  const [ligalCheck, setligalCheck] = useState("");
  const [password, setPassword] = useState("");
  const [conformpassword, setConformPassword] = useState("");

  const [nameError, setnameError] = useState("");
  const [emailError, setemailError] = useState("");
  const [mobileError, setmobileError] = useState("");
  const [altmobileError, setaltmobileError] = useState("");
  const [adharCardError, setadharCardError] = useState("");
  const [panCardError, setpanCardError] = useState("");
  const [placeError, setplaceError] = useState("");
  const [distictError, setdistictError] = useState("");
  const [shopAddressError, setshopAddressError] = useState("");
  const [pincodeError, setpincodeError] = useState("");
  const [percentageError, setpercentageError] = useState("");
  const [passwordError, setpasswordError] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (name !== "") {
      setnameError("");
    }
    if (emailcnf !== "") {
      setemailError("");
    }
    if (mobileCnf !== "") {
      setmobileError("");
    }
    if (altmobile !== "") {
      setaltmobileError("");
    }
    if (adharCard !== "") {
      setadharCardError("");
    }
    if (panCard !== "") {
      setpanCardError("");
    }
    if (place !== "") {
      setplaceError("");
    }
    if (distict !== "") {
      setdistictError("");
    }
    if (shopAddress !== "") {
      setshopAddressError("");
    }
    if (pincode !== "") {
      setpincodeError("");
    }
    if (percentage !== "") {
      setpercentageError("");
    }
    if (password === conformpassword) {
      setpasswordError("");
    }
  }, [
    name,
    emailcnf,
    mobileCnf,
    altmobile,
    adharCard,
    panCard,
    place,
    distict,
    shopAddress,
    pincode,
    percentage,
    password,
    conformpassword,
  ]);

  const numberChechChange = async (e) => {
    const value = e;
    setMobile(value);
    setmobileError("");
    if (value.length === 10) {
      const responce = await axios.get(
        `${Baseurl2}/api/v1/user/mobile/${value}`
      );

      if (responce.data.success === true) {
        setmobileError("Number already exist");
      } else {
        setMobileCnf(value);
        setmobileError("");
      }
    } else {
      setMobileCnf("");
      setmobileError("Please enter correct number");
    }
  };

  const verifyemail = async (e) => {
    const value = e;
    setEmail(value);
    setemailError("");
    if (value != "") {
      const responce = await axios.get(
        `${Baseurl2}/api/v1/user/email/${value}`
      );

      if (responce.data.success === true) {
        setemailError("Email already exist");
      } else {
        setEmailcnf(value);
        setemailError("");
      }
    }
  };

  const shopImageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(userShopImage({ shopimage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const RegisterPress = async (e) => {
    e.preventDefault();
    if (name === "") {
      setnameError("Please enter your name");
    }
    if (email === "") {
      setemailError("Please enter your email");
    }
    if (mobile === "") {
      setmobileError("Please enter your mobile number");
    }
    if (altmobile === "") {
      setaltmobileError("Please enter your alter mobile number");
    }
    if (adharCard === "") {
      setadharCardError("Please enter your adharCard number");
    }
    if (panCard === "") {
      setpanCardError("Please enter your panCard number");
    }
    if (place === "") {
      setplaceError("Please enter your place");
    }
    if (distict === "") {
      setdistictError("Please enter your distict");
    }
    if (shopAddress === "") {
      setshopAddressError("Please enter your shop address");
    }
    if (pincode === "") {
      setpincodeError("Please enter your pincode");
    }
    if (percentage === "") {
      setpercentageError("Please enter your percentage");
    }
    if (password !== conformpassword) {
      setpasswordError("Password didn't match");
    }

    const config = {
      Headers: { "Content-Type": "application/json" },
    };

    const formData = {
      name: name,
      email: email,
      password: password,
      mobile: mobile,
      altmobile: altmobile,
      Sponcermobile: "",
      adharCard: adharCard,
      panCard: panCard,
      role: value,
      place: place,
      distict: distict,
      shopAddress: shopAddress,
      pincode: pincode,
      percentage: percentage,
      shopImage: shopImages,
      ligalCheck: toggleCheckBox,
    };
    const userDetails = await axios.post(
      `${Baseurl2}/api/v1/user/register`,
      formData,
      config
    );

    if (userDetails.data.success === true) {
      const loginDetails = {
        ...userDetails.data.user,
        isAuth: true,
      };
      dispatch(authActions.signin(loginDetails));
      navigate("/");
    }
  };

  return (
    <section style={{ backgroundColor: "#9A616D" }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col col-xl-11 ">
            <div className="card" style={{ borderRadius: "1rem" }}>
              <div className="row g-0" style={{ backgroundColor: "#7e58df" }}>
                <div className="col-md-6 col-lg-5 d-none d-md-block">
                  <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"
                    alt="login form"
                    className="img-fluid"
                    style={{ borderRadius: "1rem 0 0 1rem" }}
                  />
                </div>
                <div className="col-md-6 col-lg-7 d-flex align-items-center">
                  <div className="card-body p-2 p-lg-2 text-black">
                    <form>
                      <div className="d-flex align-items-center mb-3 pb-1">
                        <span className="h1 fw-bold mb-0">
                          <img src="assets/img/logo/logo2.png" alt />
                        </span>
                      </div>
                      <h5
                        className="fw-normal mb-3 pb-3"
                        style={{ letterSpacing: 1 }}
                      >
                        Register your account
                      </h5>
                      <div className="form-outline mb-4">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <input
                              type="text"
                              id="form2Example17"
                              className=" form-control-lg my-2 form-control"
                              placeholder="Enter Name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                          {nameError === "" ? (
                            <></>
                          ) : (
                            <>
                              <p style={{ color: "red" }}>{nameError}</p>
                            </>
                          )}
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <input
                              type="text"
                              id="form2Example17"
                              className=" form-control-lg  my-2 form-control"
                              placeholder="Enter Email"
                              value={email}
                              onChange={(e) => verifyemail(e.target.value)}
                            />
                          </div>
                          {emailError === "" ? (
                            <></>
                          ) : (
                            <>
                              <p style={{ color: "red" }}>{emailError}</p>
                            </>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <input
                              type="text"
                              id="form2Example17"
                              className=" form-control-lg  my-2 form-control"
                              placeholder="Enter Mobile"
                              keyboardType="numeric"
                              value={mobile}
                              minLength={10}
                              maxLength={10}
                              onChange={(e) =>
                                numberChechChange(
                                  e.target.value.replace(/\D/g, "")
                                )
                              }
                            />
                          </div>
                          {mobileError === "" ? (
                            <></>
                          ) : (
                            <>
                              <p style={{ color: "red" }}>{mobileError}</p>
                            </>
                          )}
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <input
                              type="text"
                              id="form2Example17"
                              className=" form-control-lg  my-2 form-control"
                              placeholder="Enter Alternate Mobile"
                              value={altmobile}
                              minLength={10}
                              maxLength={10}
                              keyboardType="numeric"
                              onChange={(e) =>
                                setaltmobile(e.target.value.replace(/\D/g, ""))
                              }
                            />
                          </div>
                          {altmobileError === "" ? (
                            <></>
                          ) : (
                            <>
                              <p style={{ color: "red" }}>{altmobileError}</p>
                            </>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <input
                              type="Password"
                              id="form2Example17"
                              className=" form-control-lg  my-2 form-control"
                              placeholder="Enter Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <input
                              type="password"
                              id="form2Example17"
                              className=" form-control-lg  my-2 form-control"
                              placeholder="Enter Confirm Password"
                              value={conformpassword}
                              onChange={(e) =>
                                setConformPassword(e.target.value)
                              }
                            />
                          </div>
                          {passwordError === "" ? (
                            <></>
                          ) : (
                            <>
                              <p style={{ color: "red" }}>{passwordError}</p>
                            </>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <input
                              type="text"
                              id="form2Example17"
                              className=" form-control-lg  my-2 form-control"
                              placeholder="Enter Aadhaar no."
                              value={adharCard}
                              minLength={12}
                              maxLength={12}
                              keyboardType="numeric"
                              onChange={(e) =>
                                setadharCard(e.target.value.replace(/\D/g, ""))
                              }
                            />
                          </div>
                          {adharCardError === "" ? (
                            <></>
                          ) : (
                            <>
                              <p style={{ color: "red" }}>{adharCardError}</p>
                            </>
                          )}
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <input
                              type="text"
                              id="form2Example17"
                              className=" form-control-lg  my-2 form-control"
                              placeholder="Enter Pan no."
                              value={panCard}
                              onChange={(e) => setpanCard(e.target.value)}
                            />
                          </div>
                          {panCardError === "" ? (
                            <></>
                          ) : (
                            <>
                              <p style={{ color: "red" }}>{panCardError}</p>
                            </>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <input
                              type="text"
                              id="form2Example17"
                              className=" form-control-lg  my-2 form-control"
                              placeholder="Enter Place"
                              value={place}
                              onChange={(e) => setplace(e.target.value)}
                            />
                          </div>
                          {placeError === "" ? (
                            <></>
                          ) : (
                            <>
                              <p style={{ color: "red" }}>{placeError}</p>
                            </>
                          )}
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <input
                              type="text"
                              id="form2Example17"
                              className=" form-control-lg  my-2 form-control"
                              placeholder="Enter Dist"
                              value={distict}
                              onChange={(e) => setdistict(e.target.value)}
                            />
                          </div>
                          {distictError === "" ? (
                            <></>
                          ) : (
                            <>
                              <p style={{ color: "red" }}>{distictError}</p>
                            </>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <input
                              type="text"
                              id="form2Example17"
                              className=" form-control-lg  my-2 form-control"
                              placeholder="Enter Pincode "
                              value={pincode}
                              minLength={6}
                              maxLength={6}
                              keyboardType="numeric"
                              onChange={(e) =>
                                setpincode(e.target.value.replace(/\D/g, ""))
                              }
                            />
                          </div>
                          {pincodeError === "" ? (
                            <></>
                          ) : (
                            <>
                              <p style={{ color: "red" }}>{pincodeError}</p>
                            </>
                          )}

                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <input
                              type="text"
                              id="form2Example17"
                              className=" form-control-lg  my-2 form-control"
                              placeholder="Enter Shop Address"
                              value={shopAddress}
                              onChange={(e) => setshopAddress(e.target.value)}
                            />
                          </div>
                          {shopAddressError === "" ? (
                            <></>
                          ) : (
                            <>
                              <p style={{ color: "red" }}>{shopAddressError}</p>
                            </>
                          )}
                        </div>
                        {/* <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <input
                              type="text"
                              id="form2Example17"
                              className=" form-control-lg  my-2 form-control"
                              placeholder="Enter Percentage"
                              value={percentage}
                              onChange={(e) =>
                                setpercentage(e.target.value.replace(/\D/g, ""))
                              }
                            />
                          </div>
                        </div> */}
                        {/* <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <Form.Label
                              htmlFor="disabledTextInput"
                              className="img-label"
                            >
                              Upload Aadhaar Front View *
                            </Form.Label>

                            <Form.Control
                              type="file"
                              placeholder="enter name....."
                            />
                            <img
                              src="/assets/img/icons/house.png"
                              height={100}
                              alt="img"
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <Form.Label
                              htmlFor="disabledTextInput"
                              className="img-label"
                            >
                              Upload Aadhaar Back View *
                            </Form.Label>

                            <Form.Control
                              type="file"
                              placeholder="enter name....."
                            />
                            <img
                              src="/assets/img/icons/house.png"
                              height={100}
                              alt="img"
                            />
                          </div>
                        </div> */}
                        <div className="row">
                          {/* <div className="col-lg-6 col-md-6 col-sm-12">
                            <Form.Label
                              htmlFor="disabledTextInput"
                              className="img-label"
                            >
                              Upload Pan Front View *
                            </Form.Label>

                            <Form.Control
                              type="file"
                              placeholder="enter name....."
                            />
                            <img
                              src="/assets/img/icons/house.png"
                              height={100}
                              alt="img"
                            />
                          </div> */}
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <Form.Label
                              htmlFor="disabledTextInput"
                              className="img-label"
                            >
                              Upload Shop View *
                            </Form.Label>

                            <Form.Control
                              type="file"
                              onChange={shopImageChange}
                              placeholder="enter name....."
                            />
                            {isShopImageLoading ? (
                              <div></div>
                            ) : (
                              <img src={shopImages} height={100} alt="img" />
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12 d-flex">
                            <input
                              type="checkbox"
                              id="vehicle1"
                              name="vehicle1"
                              value="Bike"
                              onChange={() =>
                                toggleCheckBox
                                  ? setToggleCheckBox(false)
                                  : setToggleCheckBox(true)
                              }
                            ></input>
                            <p style={{ color: "#fff" }} className="mx-2">
                              {" "}
                              Agree with Terms & Conditions
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          {toggleCheckBox ? (
                            <>
                              <div
                                className="col-lg-6 col-md-6 col-sm-12"
                                onClick={(e) => RegisterPress(e)}
                              >
                                <button
                                  style={{
                                    width: "100%",
                                    height: "7vh",
                                    background: "#fff",
                                    marginTop: "4vh",
                                    borderRadius: "10px",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                  }}
                                >
                                  Sign Up
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <button
                                  style={{
                                    width: "100%",
                                    height: "7vh",
                                    background: "gray",
                                    marginTop: "4vh",
                                    borderRadius: "10px",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                  }}
                                >
                                  Sign Up
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="pt-1 mb-4"></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Registation;
