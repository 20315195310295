import React from "react";
import { HiLightBulb } from "react-icons/hi";
import { BsFillDropletFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const ServicesBBPS = () => {
  const serviceArray = [
    {
      servicename: "Electricity",
      image: "./assets/img/icons/lightbulb.png",
      route: "/service",
    },
    {
      servicename: "Gas",
      image: "./assets/img/icons/gas-cylinder.png",
      route: "/service",
    },
    {
      servicename: "Water",
      image: "./assets/img/icons/water-drop.png",
      route: "/service",
    },
    // {
    //     servicename: "Insurance",
    //     image: "./assets/img/icons/insurance.png",
    //     route: "/service",
    // },
    // {
    //     servicename: "Loan Repay",
    //     image: "./assets/img/icons/loan.png",
    //     route: "/service",
    // },
    // {
    //   servicename: "Housing Society",
    //   image: "./assets/img/icons/house.png",
    //   route: "/service",
    // },
    {
      servicename: "Landline",
      image: "./assets/img/icons/landline.png",
      route: "/service",
    },
    {
      servicename: "Postpaid",
      image: "./assets/img/icons/mobile.png",
      route: "/service",
    },
    // {
    //     servicename: "LIC Premium",
    //     image: "./assets/img/icons/life-insurance.png",
    //     route: "/service",
    // },
    {
      servicename: "Broadband",
      image: "./assets/img/icons/wifi.png",
      route: "/service",
    },
    // {
    //     servicename: "Fasttag",
    //     image: "./assets/img/icons/barrier-and-car.png",
    //     route: "/service",
    // },
  ];

  return (
    <>
    
      <section className="bbps_services">
        <div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-blog-title-wrapper text-center">
                <h3 className="tp-section-title">BBPS Services</h3>
              </div>
            </div>
          </div>
          <div className="bbps_services_main">
            {serviceArray.map((service, index) => (
              <div className="services_item m-3" key={index}>
                <Link to={service.route}>
                  <div className="services_item_icon">
                    <img src={service.image} alt="" />
                  </div>
                </Link>
                <Link to={service.route}>
                  <div className="services_item_text">
                    {service.servicename}
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesBBPS;
