import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Baseurl2 } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
  mob: "",
  otp: "",
  pageStatus: 0,
  isAuth: JSON.parse(localStorage.getItem("loginData"))?.isAuth
    ? JSON.parse(localStorage.getItem("loginData")).isAuth
    : false,
  kycStatus: false,
  loginData: JSON.parse(localStorage.getItem("loginData"))
    ? JSON.parse(localStorage.getItem("loginData"))
    : "",
  payInStatus: JSON.parse(localStorage.getItem("payInStatus"))
    ? JSON.parse(localStorage.getItem("payInStatus"))
    : "",
  clientid: "",
  agentid: "",
  email: "",
  name: "",
  createContact: "",
  createContactStep2: "",
  agentDetails: "",
  userReferal: [],
  showMenu: false,
  isClientLoading: true,
  clientLoading: true,
  agentDetailsLoading: true,
  userReferalLoading: false,
  updatedsignin: false,
  homeCurrentTab: "Home",
  shopImages: "",
  isShopImageLoading: true,
};

export const userShopImage = createAsyncThunk(
  "Authentication/userShopImage",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl2}/api/v1/user/shopimage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("image Icon Not create");
    }
  }
);

const AuthenticationSlice = createSlice({
  name: "Authentication",
  initialState,
  reducers: {
    LoginOtp(state, action) {
      state.mob = action.payload.number;
      state.otp = action.payload.otp;
      state.pageStatus = 1;
    },
    pageStatusChange(state, action) {
      state.pageStatus = action.payload;
    },
    userReferal(state, action) {
      state.userReferal = action.payload;
      state.userReferalLoading = true;
    },
    signin(state, action) {
      state.isAuth = action.payload.isAuth;
      state.loginData = action.payload;
      localStorage.setItem("loginData", JSON.stringify(action.payload));
    },
    setpayInStatus(state, action) {
      state.payInStatus = action.payload;
      localStorage.setItem("payInStatus", JSON.stringify(state.payInStatus));
    },
    setpayInStatusRemove(state, action) {
      state.payInStatus = "";
      localStorage.setItem("payInStatus", JSON.stringify(state.payInStatus));
    },
    setUpdatedsignin(state, action) {
      state.updatedsignin = true;
    },

    signout(state, action) {
      state.pageStatus = 0;
      localStorage.removeItem("loginData");
      state.isAuth = false;
    },
    createContact(state, action) {
      state.createContact = action.payload;
    },
    createContactStep2(state, action) {
      state.createContactStep2 = action.payload;
    },
    userDetailUpdate(state, action) {
      state.kycStatus = action.payload.kycStatus;
      state.loginData = action.payload;
      localStorage.setItem("loginData", JSON.stringify(state.loginData));
    },
    changeMenu(state, action) {
      state.showMenu = action.payload;
    },
    changeHomeCurrentTab(state, action) {
      state.homeCurrentTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userShopImage.pending, (state) => {
        state.isShopImageLoading = true;
      })
      .addCase(userShopImage.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.shopImages = action.payload.shopimage;
        }
        state.isShopImageLoading = false;
      })
      .addCase(userShopImage.rejected, (state) => {
        state.isShopImageLoading = true;
      })
  }

});

export const {
  LoginOtp,
  pageStatusChange,
  signin,
  signout,
  createContact,
  createContactStep2,
  userDetailUpdate,
  changeMenu,
  userReferal,
  setUpdatedsignin,
  changeHomeCurrentTab,
  setpayInStatus,
  setpayInStatusRemove
} = AuthenticationSlice.actions;
export const authActions = AuthenticationSlice.actions;
export default AuthenticationSlice.reducer;
