import React from "react";
import HomeBanner from "./home/HomeBanner";
import HomeAbout from "./home/HomeAbout";
import HomeServices from "./home/HomeServices";
import ServicesBBPS from "./home/ServicesBBPS";
import Category from "./home/Category";
import Category2 from "./home/Category2";
import { useEffect } from "react";
import { setpayInStatusRemove } from "../redux/authentication/AuthenticationSlice";

import { useDispatch, useSelector } from "react-redux";
import MoneyTransferBox from "./home/MoneyTransferBox";

const Home = () => {
  const { loginData, isAuth } = useSelector((state) => state.Authentication);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setpayInStatusRemove());
  }, []);

  return (
    <>
      <HomeBanner />
      {/* {isAuth ? (
        <>
          <MoneyTransferBox />
        </>
      ) : (
        <>
          <HomeAbout />
        </>
      )} */}

      <ServicesBBPS />
    </>
  );
};

export default Home;
