import React, { useState, useLayoutEffect, useEffect } from "react";
import axios from "axios";
import { authActions } from "../redux/authentication/AuthenticationSlice";
import { useDispatch, useSelector } from "react-redux";
import { Baseurl, Baseurl2 } from "../config/BaseUrl";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailError, setemailError] = useState("");
  const [passwordError, setpasswordError] = useState("");

  const [showPassword, setShowPassword] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginPress = async (e) => {
    e.preventDefault();
    setemailError("");
    const formData = {
      email: String(email),
      password: String(password),
    };

    const config = {
      Headers: { "Content-Type": "application/json" },
    };

    const userDetails = await axios.post(
      `${Baseurl2}/api/v1/user/login`,
      formData,
      config
    );
    if (userDetails.data.success === true) {
      const loginDetails = {
        ...userDetails.data.user,
        isAuth: true,
      };
      dispatch(authActions.signin(loginDetails));
      navigate("/");
      setEmail("");
      setPassword("");
    } else {
      setemailError("please enter valid data");
    }
  };

  return (
    <section className="vh-100" style={{ backgroundColor: "#d3e02e" }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100 mb-3">
          <div className="col col-xl-10 ">
            <div className="card " style={{ borderRadius: "1rem" }}>
              <div className="row g-0">
                <div className="col-md-6 col-lg-5 d-none d-md-block">
                  <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"
                    alt="login form"
                    className="img-fluid"
                    style={{ borderRadius: "1rem 0 0 1rem" }}
                  />
                </div>
                <div
                  className="col-md-6 col-lg-7 d-flex align-items-center"
                  style={{
                    backgroundColor: "#7e58df",
                    borderTopRightRadius: "15px",
                  }}
                >
                  <div className="card-body p-4 p-lg-5 text-black">
                    <form>
                      <div className="d-flex align-items-center mb-3 pb-1">
                        <span className="h1 fw-bold mb-0">
                          <img src="assets/img/logo/logo2.png" alt />
                        </span>
                      </div>
                      <h5
                        className="fw-normal mb-3 pb-3"
                        style={{ letterSpacing: 1 }}
                      >
                        Sign into your account
                      </h5>
                      <div className="form-outline mb-4">
                        <input
                          type="text"
                          id="form2Example17"
                          className=" form-control-lg my-2"
                          placeholder="Enter Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value.trim())}
                        />
                        <input
                          type="password"
                          // id="form2Example17"
                          className=" form-control-lg  my-2"
                          placeholder="Enter Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value.trim())}
                        />
                        {/* <label className="form-label" htmlFor="form2Example17">
                          Mobile
                        </label> */}
                      </div>
                      <div className="pt-1 mb-4">
                        <button
                          className="btn btn-dark btn-lg btn-block"
                          type="button"
                          onClick={(e) => loginPress(e)}
                        >
                          Login
                        </button>
                      </div>
                      <a className="small text-muted">Forgot password?</a>
                      <p
                        className="mb-5 pb-lg-2"
                        style={{ color: "#393f81", cursor: "pointer" }}
                        onClick={() => navigate("/registation")}
                      >
                        Don't have an account?{" "}
                        <a style={{ color: "#393f81" }}>Register here</a>
                      </p>
                      <a className="small text-muted">Terms of use.</a>
                      <a className="small text-muted">Privacy policy</a>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
