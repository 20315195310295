import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const ProfileSection = () => {
  const { loginData, isAuth } = useSelector((state) => state.Authentication);
  const navigate = useNavigate();
  return (
    <div className="profile-section my-2">
      <div className="profile-section-inner">
        <div className="row">
          <div className="col-lg-4 col-md-5 col-sm-12 profile-section-leftPanel">
            <div className="leftPanel-content">
              <div>Name:{loginData.name}</div>
              <div>Email:{loginData.email}</div>
              <div>Phn:+91 {loginData.mobile}</div>
              <div>Available Balance : {loginData.mainWallet}</div>
              <div>Commission Earn: 0</div>
            </div>
            <div
              className="leftPanel-content"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h6
                style={{
                  color: "#7e58df",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                // onClick={() => navigate("/registation")}
              >
                Add New Referals
              </h6>
            </div>
          </div>
          <div className="col-lg-8 col-md-7 col-sm-12 profile-section-rightPanel">
            <div className="righttPanel-content">
              <div className="about-me">
                <div>Role:{loginData.role}</div>
                <div>Percentage:{loginData.percentage}</div>
                <div>Place:{loginData.place}</div>
                <div>Distict:{loginData.distict}</div>
                <div>Pincode:{loginData.pincode}</div>
                <div>Shop Address:{loginData.shopAddress}</div>
              </div>
              <div className="my-info">
                <div>Pan:{loginData.panCard}</div>
                <div>Aadhaar:{loginData.adharCard}</div>
              </div>
              <div className="my-document">
                <div className="row mt-10">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div>
                      <h2 className="img-label">Aadhaar Documents </h2>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <img src={loginData.shopImage} width={150} alt="" />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <img src={loginData.shopImage} width={150} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div>
                      <h2 className="img-label">Pan Documents </h2>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <img src={loginData.shopImage} width={150} alt="" />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <img src={loginData.shopImage} width={150} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div>
                      <h2 className="img-label">Shop Image </h2>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <img src={loginData.shopImage} width={150} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProfileSection;
