import React, { Fragment, useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";



const MoneyTransferHistory = () => {
  const [productall, setProductall] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const url = `https://fakestoreapi.com/products`;
      const fetchOrder = await axios.get(url);
      const grocTotal = fetchOrder.data;
      setProductall(grocTotal);
      if (fetchOrder.data.success === true) {
      }
    };
    fetchData();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
      // align: "center",
      headerAlign: "center",
    },
    {
      field: "title",
      width: 350,
      headerAlign: "center",
      headerName: "TITLE",
      renderCell: (params) => {
        return <div className="d-flex my-3">{params.row.title}</div>;
      },
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper my-3">
          <div className="card me-2 ms-2">
            <div className="card-footer mb-2">
              <h5 style={{ textTransform: "capitalize", fontWeight: "bold" }}>
                Money Transfer History
              </h5>
            </div>
            <div
              style={{ height: 650, width: "100%" }}
              className="d-flex m-auto text-align-center"
            >
              <DataGrid
                rows={productall}
                columns={columns}
                getRowId={(row) => row.id}
                rowHeight={10}
                getRowHeight={() => "auto"}
                pageSize={20}
                rowsPerPageOptions={[5]}
                sx={{
                  ".MuiDataGrid-columnSeparator": {
                    display: "none",
                  },
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                }}
              />
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default MoneyTransferHistory;
