import { configureStore } from "@reduxjs/toolkit";

import AuthenticationReducer from "./authentication/AuthenticationSlice";
import BankDetailsReducer from "./bankDetails/BankDetailsSlice";
import TransactionReducer from "./transactions/TransactionSlice";
import KycReducer from "./Kyc/KycSlice";


export const store = configureStore({
    reducer:{
        Authentication: AuthenticationReducer,
        BankDetails: BankDetailsReducer,
        Transaction: TransactionReducer,
        Kyc: KycReducer,
    },
})